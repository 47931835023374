@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
  font-family: "UncutSans-Variable";
  /* src: url("../assets/fonts/UncutSans-Variable.ttf"); */
  src: url("./assets/fonts/UncutSans-Variable.ttf");

}

body {
  margin: 0;
  font-family: "UncutSans-Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

