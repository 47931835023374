@media print {
  .print\:w-full {
    width: 100% !important;
  }
  .print\:h-auto {
    height: auto !important;
  }
  .print\:border-none {
    border: none !important;
  }
  .print\:p-0 {
    padding: 0 !important;
  }
  .print\:static {
    position: static !important;
  }
  .print\:rounded-none {
    border-radius: 0 !important;
  }
  .print\:relative {
    position: relative !important;
  }
  .print\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .print\:text-xs {
    font-size: 0.75rem !important;
  }
}

/* Target Windows users specifically */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .windows-scrollbar {
    scrollbar-width: auto;
  }

  .windows-scrollbar::-webkit-scrollbar {
    width: 12px;
  }

  .windows-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
