/* customStyles.css */

/* Change the background and text color of the selected menu item */
.custom-sider .ant-menu-item-selected {
    background-color: #ffffff !important; /* Your desired background color */
    color: #000000 !important; /* Your desired text color */
  }
  
  /* Change the background and text color of the default selected keys */
  .custom-sider .ant-menu-item-active {
    background-color: #ffffff !important; /* Your desired background color */
    color: #000000 !important; /* Your desired text color */
  }
  